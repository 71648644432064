<template>
  <div>
    <UserSettingsSideBar @onSave="onSave" :open="true" />
    <AccountSettingsSideBar @onSave="onSaveSettings" :open="true" />
    <b-row class="portadaRow" :style="isEditMode === null ? 'cursor:pointer;' : null">
      <div class="portada"></div>
      <div class="avatar">
        <b-form-file
          ref="avatarInput"
          class="inputAvatar"
          :state="Boolean(true)"
          accept="image/*"
          @change="onUploadFile($event, 'avatar')"
        />
        <UserCard
          :user="user"
          :isExternal="isExternal"
          :isEditMode="isEditMode"
          :avatarImg="avatarImg"
        />
      </div>
    </b-row>
    <b-container class="bv-example-row">
      <b-row class="infoRow">
        <b-col :cols="isMobile ? '12' : '3'" :class="isMobile ? 'mBtnsCol' : 'btnCol'">
          <vs-button
            icon
            @click="onEdit"
            v-if="!isEditMode && !isExternal"
            relief
            block
            :size="isMobile ? 'mini' : 'default'"
            animation-type="rotate"
          >
            <i class="fas fa-pen"></i> Edit
            <template #animate>
              <i class="fas fa-pen"></i>
            </template>
          </vs-button>
          <vs-button
            icon
            @click="onOpenSettings"
            v-if="!isSettingsMode && !isExternal"
            relief
            color="tumblr"
            block
            :size="isMobile ? 'mini' : 'default'"
            animation-type="rotate"
          >
            <i class="fas fa-user-cog"></i> Ajustes
            <template #animate>
              <i class="fas fa-user-cog"></i>
            </template>
          </vs-button>
          <vs-button
            icon
            @click="onSave"
            v-else-if="!isExternal && (isEditMode || isSettingsMode)"
            relief
            success
            block
            :size="isMobile ? 'mini' : 'default'"
            animation-type="rotate"
          >
            <i class="fas fa-save"></i> Save
            <template #animate>
              <i class="fas fa-save"></i>
            </template>
          </vs-button>
          <br />
          <vs-button
            @click="onFollow"
            v-if="isExternal && !isFollowed"
            icon
            relief
            color="#dc3545"
            block
            :size="isMobile ? 'mini' : 'default'"
            animation-type="rotate"
          >
            <i class="fas fa-heart"></i> Follow
            <template #animate>
              <i class="fas fa-heart"></i>
            </template>
          </vs-button>
          <vs-button
            @click="onUnfollow"
            v-if="isExternal && isFollowed"
            icon
            relief
            color="#dc3545"
            block
            :size="isMobile ? 'mini' : 'default'"
            animation-type="rotate"
          >
            <i class="fas fa-heart-broken"></i> Unfollow
            <template #animate>
              <i class="fas fa-heart-broken"></i>
            </template>
          </vs-button>
          <br />
          <vs-button
            v-if="isExternal"
            @click="openChat"
            icon
            relief
            color="#5B8200"
            block
            :size="isMobile ? 'mini' : 'default'"
            animation-type="vertical"
          >
            Message
            <template #animate> <i class="fas fa-comment-dots"></i> Send </template>
          </vs-button>
        </b-col>
      </b-row>
      <b-row class="contentRow">
        <div>
          <h1>Panel de control</h1>

          <div class="groupCardContainer">
            <div
              v-for="(group, ind) in groups"
              :key="'group_' + ind"
              class="groupCard"
              :style="'background-color:' + group.color"
              @click="goToGroup(group.title)"
            >
              <h3>{{ group.title }}</h3>
              <Iculture v-if="group.topic === 1" />
              <Isport v-else-if="group.topic === 2" />
              <Iradio v-else-if="group.topic === 3" />
              <Ihistorical v-else-if="group.topic === 4" />
              <Icustom v-else-if="group.topic === 5" />
              <div class="groupCardInfo">
                <div>
                  <div>
                    <b> <i class="fas fa-users"></i> {{ group.participants }}</b>
                  </div>
                  <div>
                    <b><i class="fas fa-comment-dots"></i> {{ group.comments }}</b>
                  </div>
                  <div>
                    <b><i class="fas fa-thumbs-up"></i> {{ group.likes }}</b>
                  </div>
                  <div>
                    <b><i class="far fa-images"></i> {{ group.media }}</b>
                  </div>
                </div>
                <div>
                  <h1>{{ group.events }}</h1>
                </div>
              </div>
            </div>
            <div class="groupCard newgroupCard" @click="active3 = true">
              <h3>Nuevo grupo</h3>
              <i class="fas fa-plus-circle fa-7x"></i>
            </div>
          </div>
        </div>
        <div class="moreInfo">
          <div class="cardInfo">
            <h3>Followers</h3>
            <Ifollow />
            <h1>+31</h1>
          </div>
          <div class="cardInfo">
            <h3>Interacciones</h3>
            <Iinteraction />
            <h1>+429</h1>
          </div>
        </div>
      </b-row>
    </b-container>
    <vs-dialog width="300px" not-center v-model="active3">
      <template #header>
        <h4 class="not-margin">Crear un nuevo <b>Grupo</b></h4>
      </template>

      <div class="con-content">
        <vs-input
          style="margin-bottom:2rem;"
          v-model="newGroup.title"
          label-placeholder="Nombre del grupo"
        ></vs-input>
        <br /><br />

        <vs-input
          v-model="newGroup.color"
          type="color"
          label-placeholder="Color del grupo"
        ></vs-input>
        <div
          :style="'width:25px; height:25px; border-radius:50%; background-color:' + newGroup.color"
        ></div>
      </div>

      <template #footer>
        <div class="con-footer" style="display:flex; justify-content:space-between;">
          <vs-button @click="active3 = false" danger> Cancel </vs-button>
          <vs-button @click="saveGroup" success>
            Save
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import CardGrid from "@/components/CardGrid.vue";
import UserCard from "../components/CARDS/UserCard.vue";
import FollowersList from "@/components/FollowersList.vue";
import UserSettingsSideBar from "@/components/SIDEBARS/UserSettingsSB.vue";
import AccountSettingsSideBar from "@/components/SIDEBARS/AccountSettingsSB.vue";
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";
import {
  Iculture,
  Isport,
  Iradio,
  Ifollow,
  Iinteraction,
  Ihistorical,
  Icustom
} from "@/components/ILLUSTRATIONS/";

import {
  loadingMix,
  noficationsDialog,
  authMix,
  eventMix,
  followMix,
  chatMix
} from "@/common/mixin.js";
import { mapState } from "vuex";

export default {
  name: "Ayuntamiento",
  mixins: [loadingMix, noficationsDialog, authMix, eventMix, followMix, chatMix],
  components: {
    CardGrid,
    UserCard,
    Iculture,
    Iradio,
    Isport,
    Ifollow,
    Iinteraction,
    Ihistorical,
    Icustom,
    UserSettingsSideBar,
    AccountSettingsSideBar,
    PacmanLoader,
    FollowersList
  },
  methods: {
    onInit(params = null) {
      const { id } = this.$store.state.currentUser;
      this.$route.params.creator = params
        ? params.creator || this.$route.params.creator
        : this.$route.params.creator;
      const { creator } = this.$route.params;
      if (creator && creator.id && id) {
        this.isExternal = creator.id !== id;
        if (this.isExternal) {
          this.getProfileData(creator.id);
          // this.getFollowing(creator.id, id);
        } else {
          this.getProfileData(id);
        }
      } else {
        this.isExternal = false;
        this.getProfileData(id);
      }
    },

    saveGroup() {
      this.groups.push(this.newGroup);
      this.active3 = false;
    },

    goToGroup(title) {
      this.$router.push({ path: "/Ayuntamiento/1/Group", query: { title } });
    },

    onOpenSettings() {
      console.log(11);
      this.$store.commit("showAccountSettingsSB", true);
      this.isSettingsMode = true;
    },

    onEdit() {
      this.$store.commit("showUserSettingsSB", true);
      this.isEditMode = true;
    },

    async onUnfollow() {
      const { currentUser } = this.$store.state;
      const { id } = currentUser;
      const { creator } = this.$route.params;
      const deletedInd = this.user.followDetail.findIndex(detail => detail.created_by === id);
      this.user.followDetail.splice(deletedInd, 1);
      this.user.followers -= 1;
      this.firebaseUpdateUser(this.user.id || creator.id, this.user);
      this.isFollowed = false;
    },

    async onFollow() {
      const { currentUser } = this.$store.state;
      const { id, profileImg, username } = currentUser;
      const { creator } = this.$route.params;
      let { followers } = this.user;
      if (id) {
        const myFollow = [
          {
            created_by: id,
            created_at: new Date(),
            username,
            authorImg: profileImg
          }
        ];
        this.user.followers = followers >= 0 ? followers + 1 : 1;
        this.user.followDetail = this.user.followDetail
          ? [...new Set(this.user.followDetail.concat(...myFollow))]
          : myFollow;
        this.firebaseUpdateUser(this.user.id || creator.id, this.user);
        this.isFollowed = true;
        this.sendPush(`${username} es tu nuevo follower`, [creator.id], "Follow");
        this.notificateLoginSuccess("Followed con exito", "Ahora sigues a  " + creator.username);
      }
    },

    checkFollowed() {
      const { currentUser } = this.$store.state;
      const { id } = currentUser;
      const ind = this.user.followDetail
        ? this.user.followDetail.findIndex(detail => detail.created_by === id)
        : -1;
      this.isFollowed = ind > -1;
    },

    async onSave(data) {
      if (this.isSettingsMode) {
        this.onSaveSettings();
      }
      const currentUser = await this.firebaseCurrentUser();
      if (this.previewImgFile && currentUser) {
        await this.firebaseSetProfileImg(currentUser.uid, this.previewImgFile);
      } else if (data) {
        const { password } = data;
        if (password && password !== "") {
          currentUser.updatePassword(password);
        } else {
          delete data.password;
        }
        await this.firebaseUpdateUser(currentUser.uid, data);
      }
      const user = await this.firebaseGetUser(currentUser.uid);
      this.$store.commit("setUser", user);
      this.isEditMode = false;
    },

    async onSaveSettings(data) {
      this.isSettingsMode = false;
    },

    onChangeImg(oEvent, type) {
      oEvent.stopPropagation();
      oEvent.cancelBubble = true;
      if (this.isEditMode && type === "avatar") {
        this.$refs.avatarInput.$el.children[0].click();
      } else if (this.isEditMode && type === "portada") {
        this.$refs.portadaInput.$el.children[0].click();
      }
    },

    onUploadFile(oEvent, type) {
      const image = oEvent.target.files[0];
      this.previewImgFile = image;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = oEvent2 => {
        this.previewImage = oEvent2.target.result;
        if (type === "avatar") {
          this.avatarImg = oEvent2.target.result;
        } else {
          this.portadaImg = oEvent2.target.result;
        }
      };
      oEvent.stopPropagation();
      oEvent.cancelBubble = true;
    },

    async getProfileData(externalUser = null) {
      if (externalUser) {
        this.user = await this.firebaseGetUser(externalUser);
        this.getEvents(externalUser);
        this.checkFollowed();
        try {
          this.avatarImg = await this.firebaseGetProfileImg(externalUser);
        } catch (error) {
          console.log(error);
        }
      } else {
        const currentUser = await this.firebaseCurrentUser();
        if (currentUser) {
          this.user = await this.firebaseGetUser(currentUser.uid);
          this.getEvents(currentUser.uid);
          this.avatarImg = await this.firebaseGetProfileImg(currentUser.uid);
        }
      }
    },

    async getFollowing(followed_Id, follower) {},

    async openChat() {
      const { id, username, profileImg } = this.$store.state.currentUser;
      const { creator } = this.$route.params;
      const chat = await this.getChat("single", [id, creator.id]);
      if (!chat) {
        const data = {
          created_at: new Date(),
          participants_id: [id, creator.id],
          participants: [
            { id, username, profileImg: profileImg || null },
            { id: creator.id, username: creator.username, profileImg: creator.profileImg || null }
          ],
          messages: [],
          type: "single"
        };
        const newChat = await this.createChat(data);
        data.id = newChat.id;
        this.openChatMix(data);
      } else {
        this.openChatMix(chat);
      }
    },

    async getEvents(user_uid) {
      this.createdEvents = await this.getFilteredEvents("created_by", user_uid);
      this.participatedEvents = await this.getFilteredEvents(
        "participants",
        user_uid,
        "array-contains"
      );
    }
  },
  computed: mapState(["currentUser"]),
  watch: {
    currentUser(newValue, oldValue) {
      if (newValue) {
        this.user = newValue;
        this.avatarImg = newValue.profileImg;
        this.getProfileData(newValue.id);
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.isMobile = isMobile;
    }
    this.onInit();
  },
  beforeRouteUpdate(to, from, next) {
    this.onInit(to.params);
    next();
  },
  data() {
    return {
      selectedTab: 0,
      active3: false,
      isMobile: false,
      user: null,
      newGroup: {
        title: "",
        events: 0,
        participants: 0,
        comments: 0,
        likes: 0,
        media: 0,
        topic: 0,
        color: "#3F6CE0"
      },
      groups: [
        {
          title: "Cultura",
          events: 18,
          participants: 496,
          comments: 32,
          likes: 178,
          media: 221,
          color: "#3F6CE0",
          topic: 1
        },
        {
          title: "Deporte",
          events: 9,
          participants: 397,
          comments: 52,
          likes: 378,
          media: 65,
          color: "#F83255",
          topic: 2
        },
        {
          title: "Radio",
          events: 5,
          participants: 40,
          comments: 32,
          likes: 178,
          media: 221,
          color: "#F8A832",
          topic: 3
        },
        {
          title: "Efemérides",
          events: 11,
          participants: 164,
          comments: 32,
          likes: 101,
          media: 12,
          color: "#004347",
          topic: 4
        },
        {
          title: "Personalizado",
          events: 18,
          participants: 496,
          comments: 32,
          likes: 178,
          media: 221,
          color: "#038890",
          topic: 5
        }
      ],
      createdEvents: [],
      participatedEvents: [],
      aFollowers: [],
      isFollowed: false,
      isExternal: true,
      isEditMode: false,
      isSettingsMode: false,
      previewImgFile: null,
      avatarImg: "https://upload.wikimedia.org/wikipedia/commons/3/33/Mr._Bean_2011.jpg",
      privateImg:
        "https://p1.pxfuel.com/preview/272/573/172/iphone-taking-photo-phone-anonymous-royalty-free-thumbnail.jpg",
      portadaImg:
        "https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg"
    };
  }
};
</script>

<style scoped>
@media (max-width: 575px) {
  .container {
    padding: 0;
  }

  .infoRow,
  .contentRow {
    width: 100%;
    min-height: 100px !important;
    overflow: scroll;
    padding: 1rem;
    grid-template: "." !important;
  }

  .btnCol {
    padding: 0;
  }

  .hobbies {
    width: 300px;
  }

  .portada {
    width: 100%;
    height: 300px;
    margin: auto;
    background-image: url("https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg");
    background-size: cover;
  }

  .avatarImg {
    right: 60%;
  }

  .avatar {
    background-image: url("https://static.thenounproject.com/png/207964-200.png");
    background-size: cover;
    position: absolute;
    top: 30%;
    /* right: 35% !important; */
    left: 1%;
  }

  div[role="tabpanel"] {
    overflow: auto;
  }

  .portadaRow {
    margin: 0;
  }

  .type-1 {
    text-align: start;
  }

  .groupCardContainer {
    grid-template: "." !important;
  }

  .moreInfo {
    padding: 3rem 1rem;
    margin-top: 4rem;
    margin-left: 0 !important;
    border-left: none !important;
    border-top: 1px solid;
  }
}

@media (min-width: 576px) {
  .infoRow,
  .contentRow {
    width: 80%;
  }

  .infoRow {
    flex-direction: row-reverse;
    min-height: none;
    width: 100%;
  }
}

.ownEvents,
.participateEvents,
.followers,
.hobbies,
.prices {
  padding: 1rem;
  box-shadow: 5px 3px 20px 0px #17a2b8;
  border-radius: 2rem;
  margin: 1rem;
}

.portada {
  background-image: url("https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg");
  background-size: cover;
  width: 100%;
  filter: brightness(0.5);
  z-index: -1;
}

.portada > img {
  max-height: 300px;
  min-width: 80%;
}

.portadaRow {
  height: 200px;
}

.contentRow {
  display: grid;
  grid-template: ". .";
}

.infoRow,
.contentRow {
  background: white;
  min-height: 160px;
  /* width: 80%; */
  margin: auto;
}

.inputAvatar {
  display: none;
}

.avatar {
  background-image: url("https://static.thenounproject.com/png/207964-200.png");
  background-size: cover;
  position: absolute;
  top: 10%;
  /* right: 74%; */
  left: 1%;
}

.avatar > img {
  height: 200px;
}

.avatarImg,
.portadaImg {
  filter: opacity(0.5);
  cursor: pointer;
}

.col1 {
  text-align: start;
  margin-top: 4rem;
}

.intereses {
  display: flex;
}

br {
  display: contents;
}

.bestMoments {
  display: flex;
  justify-content: space-evenly;
}

.groupCardInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.groupCardInfo > div:first-child {
  text-align: start;
}

.groupCardInfo > div:last-child {
  text-align: end;
}

.groupCard {
  cursor: pointer;
  border-radius: 2rem;
  width: 100%;
  padding: 1rem;
  background: #17a2b8;
  color: white;
  box-shadow: 5px 7px 10px #00000085;
  min-width: 225px;
}

.newgroupCard {
  background: var(--main-bg);
  color: #17a2b8;
  border: 2px dotted #17a2b8;
}

.groupCardContainer {
  display: grid;
  grid-template: ". . .";
  grid-gap: 1rem 1rem;
}

.moreInfo {
  padding: 3rem 1rem;
  margin-left: 4rem;
  border-left: 1px solid;
}

.cardInfo {
  background: #17a2b8;
  color: white;
  border-radius: 2rem;
  box-shadow: 6px 6px 10px grey;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
}

.mBtnsCol {
  display: flex;
}
</style>
