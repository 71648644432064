<template>
  <div>
    <UserSettingsSideBar @onSave="onSave" :open="true" />
    <AccountSettingsSideBar @onSave="onSaveSettings" :open="true" />
    <b-row class="portadaRow" :style="isEditMode === null ? 'cursor:pointer;' : null">
      <div class="portada"></div>
      <div class="avatar">
        <b-form-file
          ref="avatarInput"
          class="inputAvatar"
          :state="Boolean(true)"
          accept="image/*"
          @change="onUploadFile($event, 'avatar')"
        />
        <vs-card type="1" v-if="user">
          <template #title>
            <h3>
              {{ user.isPrivate && isExternal ? "Nombre" : user.firstname }}
              {{ user.isPrivate && isExternal ? "Privado" : user.lastname }}
            </h3>
          </template>
          <template #img>
            <img :src="`${user.isPrivate && isExternal ? privateImg : avatarImg}`" alt="Image 1" />
          </template>
          <template #text>
            <i>{{
              user.isPrivate && isExternal ? "Calle falsa 123" : user.nombre
            }}</i>
            <p>Departament de <b>{{department}}</b></p>
          </template>
          <template #interactions>
            <vs-button danger icon v-if="isExternal">
              <i class="fas fa-heart"></i>
            </vs-button>
            <vs-button
              class="btn-chat"
              v-if="isEditMode"
              shadow
              primary
              @click="onChangeImg($event, 'avatar')"
            >
              <i class="fas fa-pencil-alt"></i>
            </vs-button>
          </template>
        </vs-card>
      </div>
    </b-row>
    <b-container class="bv-example-row">
      <b-row class="infoRow">
        <b-col class="col1"> </b-col>
        <b-col>
          <!-- Diverso contenido/info aquí... -->
        </b-col>
        <b-col cols="3" class="btnCol">
          <vs-button
            icon
            @click="onEdit"
            v-if="!isEditMode && !isExternal"
            relief
            block
            animation-type="rotate"
          >
            <i class="fas fa-pen"></i> Edit
            <template #animate>
              <i class="fas fa-pen"></i>
            </template>
          </vs-button>
          <vs-button
            icon
            @click="onOpenSettings"
            v-if="!isSettingsMode && !isExternal"
            relief
            color="tumblr"
            block
            animation-type="rotate"
          >
            <i class="fas fa-pen"></i> Ajustes
            <template #animate>
              <i class="fas fa-pen"></i>
            </template>
          </vs-button>
          <vs-button
            icon
            @click="onSave"
            v-else-if="!isExternal && (isEditMode || isSettingsMode)"
            relief
            success
            block
            animation-type="rotate"
          >
            <i class="fas fa-save"></i> Save
            <template #animate>
              <i class="fas fa-save"></i>
            </template>
          </vs-button>
          <br />
          <vs-button
            @click="onFollow"
            v-if="isExternal && !isFollowed"
            icon
            relief
            color="#dc3545"
            block
            animation-type="rotate"
          >
            <i class="fas fa-heart"></i> Follow
            <template #animate>
              <i class="fas fa-heart"></i>
            </template>
          </vs-button>
          <vs-button
            @click="onUnfollow"
            v-if="isExternal && isFollowed"
            icon
            relief
            color="#dc3545"
            block
            animation-type="rotate"
          >
            <i class="fas fa-heart-broken"></i> Unfollow
            <template #animate>
              <i class="fas fa-heart-broken"></i>
            </template>
          </vs-button>
          <br />
          <vs-button
            v-if="isExternal"
            @click="openChat"
            icon
            relief
            color="#5B8200"
            block
            animation-type="vertical"
          >
            Message
            <template #animate> <i class="fas fa-comment-dots"></i> Send </template>
          </vs-button>
        </b-col>
      </b-row>
      <b-row class="contentRow">
        <div>
          <div class="groupCardContainer">
            <div>
              <h2>Agenda</h2>
              <vs-card
                type="3"
                v-for="(event, ind) in nextEvents"
                :key="'group_' + ind"
                class="agendaCard"
              >
                <template #title>
                  <h3>{{ event.title }}</h3>
                </template>
                <template #img>
                  <img :src="event.img" alt="" />
                </template>
                <template #text>
                  <div class="eventCardInfo">
                    <div>
                      <p style="text-align: initial;">
                        {{ event.description }}
                      </p>
                    </div>
                    <div class="date">
                      <h5>{{ event.date }}</h5>
                    </div>
                  </div>
                  <small
                    >{{ event.startAt }} &nbsp; {{ event.duration }} &nbsp; {{ event.address }}
                  </small>
                </template>
                <template #interactions>
                  <vs-button danger icon>
                    <i class="far fa-heart"></i>
                  </vs-button>
                </template>
              </vs-card>
            </div>
            <div>
              <h2>Directo</h2>
              <vs-card type="1" class="">
                <template #title>
                  <h3>{{ currentEvent.title }}</h3>
                </template>
                <template #img>
                  <img :src="currentEvent.img" alt="" />
                </template>
                <template #text>
                  <p>
                    {{ currentEvent.description }}
                  </p>
                </template>
                <template #interactions>
                  <vs-button danger icon>
                    <i class="far fa-heart"></i>
                  </vs-button>
                  <vs-button class="btn-chat" shadow primary>
                    <i class="fas fa-comment-dots"></i>
                    <span class="span">
                      54
                    </span>
                  </vs-button>
                </template>
              </vs-card>
            </div>
          </div>
        </div>
        <div class="moreInfo">
          <div class="groupCard newgroupCard" @click="active = true">
            <h3><i class="fas fa-plus-circle"></i> Nuevo Evento</h3>
          </div>
          <div class="cardInfo">
            <h3>Followers</h3>
            <h1>+13</h1>
          </div>
          <div class="cardInfo">
            <h3>Interacciones</h3>
            <h1>+142</h1>
          </div>
        </div>
      </b-row>
    </b-container>
    <CreateEvent :onClose="onClose" :active="this.active" />
  </div>
</template>

<script>
import CardGrid from "@/components/CardGrid.vue";
import CreateEvent from "@/views/CreateEvent.vue";
import FollowersList from "@/components/FollowersList.vue";
import UserSettingsSideBar from "@/components/SIDEBARS/UserSettingsSB.vue";
import AccountSettingsSideBar from "@/components/SIDEBARS/AccountSettingsSB.vue";
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";
import {
  loadingMix,
  noficationsDialog,
  authMix,
  eventMix,
  followMix,
  chatMix
} from "@/common/mixin.js";
import { mapState } from "vuex";

export default {
  name: "Ayuntamiento",
  mixins: [loadingMix, noficationsDialog, authMix, eventMix, followMix, chatMix],
  components: {
    CardGrid,
    CreateEvent,
    UserSettingsSideBar,
    AccountSettingsSideBar,
    PacmanLoader,
    FollowersList
  },
  methods: {
    onInit(params = null) {
      const { id } = this.$store.state.currentUser;
      this.department = this.$route.query.title || "";
      this.$route.params.creator = params
        ? params.creator || this.$route.params.creator
        : this.$route.params.creator;
      const { creator } = this.$route.params;
      if (creator && creator.id && id) {
        this.isExternal = creator.id !== id;
        if (this.isExternal) {
          this.getProfileData(creator.id);
          // this.getFollowing(creator.id, id);
        } else {
          this.getProfileData(id);
        }
      } else {
        this.isExternal = false;
        this.getProfileData(id);
      }
    },

    onClose() {
      this.active = false;
    },

    onOpenSettings() {
      console.log(11);
      this.$store.commit("showAccountSettingsSB", true);
      this.isSettingsMode = true;
    },

    onEdit() {
      this.$store.commit("showUserSettingsSB", true);
      this.isEditMode = true;
    },

    async onUnfollow() {
      const { currentUser } = this.$store.state;
      const { id } = currentUser;
      const { creator } = this.$route.params;
      const deletedInd = this.user.followDetail.findIndex(detail => detail.created_by === id);
      this.user.followDetail.splice(deletedInd, 1);
      this.user.followers -= 1;
      this.firebaseUpdateUser(this.user.id || creator.id, this.user);
      this.isFollowed = false;
    },

    async onFollow() {
      const { currentUser } = this.$store.state;
      const { id, profileImg, username } = currentUser;
      const { creator } = this.$route.params;
      let { followers } = this.user;
      if (id) {
        const myFollow = [
          {
            created_by: id,
            created_at: new Date(),
            username,
            authorImg: profileImg
          }
        ];
        this.user.followers = followers >= 0 ? followers + 1 : 1;
        this.user.followDetail = this.user.followDetail
          ? [...new Set(this.user.followDetail.concat(...myFollow))]
          : myFollow;
        this.firebaseUpdateUser(this.user.id || creator.id, this.user);
        this.isFollowed = true;
        this.sendPush(`${username} es tu nuevo follower`, [creator.id], "Follow");
        this.notificateLoginSuccess("Followed con exito", "Ahora sigues a  " + creator.username);
      }
    },

    checkFollowed() {
      const { currentUser } = this.$store.state;
      const { id } = currentUser;
      const ind = this.user.followDetail
        ? this.user.followDetail.findIndex(detail => detail.created_by === id)
        : -1;
      this.isFollowed = ind > -1;
    },

    async onSave(data) {
      if (this.isSettingsMode) {
        this.onSaveSettings();
      }
      const currentUser = await this.firebaseCurrentUser();
      if (this.previewImgFile && currentUser) {
        await this.firebaseSetProfileImg(currentUser.uid, this.previewImgFile);
      } else if (data) {
        const { password } = data;
        if (password && password !== "") {
          currentUser.updatePassword(password);
        } else {
          delete data.password;
        }
        await this.firebaseUpdateUser(currentUser.uid, data);
      }
      const user = await this.firebaseGetUser(currentUser.uid);
      this.$store.commit("setUser", user);
      this.isEditMode = false;
    },

    async onSaveSettings(data) {
      this.isSettingsMode = false;
    },

    onChangeImg(oEvent, type) {
      oEvent.stopPropagation();
      oEvent.cancelBubble = true;
      if (this.isEditMode && type === "avatar") {
        this.$refs.avatarInput.$el.children[0].click();
      } else if (this.isEditMode && type === "portada") {
        this.$refs.portadaInput.$el.children[0].click();
      }
    },

    onUploadFile(oEvent, type) {
      const image = oEvent.target.files[0];
      this.previewImgFile = image;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = oEvent2 => {
        this.previewImage = oEvent2.target.result;
        if (type === "avatar") {
          this.avatarImg = oEvent2.target.result;
        } else {
          this.portadaImg = oEvent2.target.result;
        }
      };
      oEvent.stopPropagation();
      oEvent.cancelBubble = true;
    },

    async getProfileData(externalUser = null) {
      if (externalUser) {
        this.user = await this.firebaseGetUser(externalUser);
        this.getEvents(externalUser);
        this.checkFollowed();
        try {
          this.avatarImg = await this.firebaseGetProfileImg(externalUser);
        } catch (error) {
          console.log(error);
        }
      } else {
        const currentUser = await this.firebaseCurrentUser();
        if (currentUser) {
          this.user = await this.firebaseGetUser(currentUser.uid);
          this.getEvents(currentUser.uid);
          this.avatarImg = await this.firebaseGetProfileImg(currentUser.uid);
        }
      }
    },

    async getFollowing(followed_Id, follower) {},

    async openChat() {
      const { id, username, profileImg } = this.$store.state.currentUser;
      const { creator } = this.$route.params;
      const chat = await this.getChat("single", [id, creator.id]);
      if (!chat) {
        const data = {
          created_at: new Date(),
          participants_id: [id, creator.id],
          participants: [
            { id, username, profileImg: profileImg || null },
            { id: creator.id, username: creator.username, profileImg: creator.profileImg || null }
          ],
          messages: [],
          type: "single"
        };
        const newChat = await this.createChat(data);
        data.id = newChat.id;
        this.openChatMix(data);
      } else {
        this.openChatMix(chat);
      }
    },

    async getEvents(user_uid) {
      this.createdEvents = await this.getFilteredEvents("created_by", user_uid);
      this.participatedEvents = await this.getFilteredEvents(
        "participants",
        user_uid,
        "array-contains"
      );
    }
  },
  computed: mapState(["currentUser"]),
  watch: {
    currentUser(newValue, oldValue) {
      if (newValue) {
        this.user = newValue;
        this.avatarImg = newValue.profileImg;
        this.getProfileData(newValue.id);
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.onInit();
  },
  beforeRouteUpdate(to, from, next) {
    this.onInit(to.params);
    next();
  },
  data() {
    return {
      selectedTab: 0,
      user: null,
      active: false,
      currentEvent: {
        title: "Exposició 'Joan Triadú a la ciutat dels llibres'",
        description: "",
        address: "Biblioteca Martorell",
        img:
          "https://multimedia-wp.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/05/26182214/Exposicio-Joan-Triadu.jpg",
        events: 18,
        participants: 496,
        comments: 32,
        likes: 178,
        media: 221,
        color: "#3F6CE0"
      },
      nextEvents: [
        {
          title: "Teatre Club Social Punt de Trobada",
          description: "'En un altre planeta'",
          date: "04 Jul",
          startAt: "18:00",
          duration: "1 h",
          img:
            "https://multimedia-wp.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/06/29180957/Screenshot_20210629_164842.jpg",
          address: "Casa de Cultura La Vila",
          events: 18,
          participants: 496,
          comments: 32,
          likes: 178,
          media: 221,
          color: "#3F6CE0"
        },
        {
          title: "Concurs 'Llegir té premi'",
          description: "",
          date: "05 Jul",
          startAt: "17:00",
          duration: "24 h",
          img:
            "https://multimedia-wp.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/05/26184830/Concurs-llegir-te-premi.jpg",
          address: "",
          events: 18,
          participants: 496,
          comments: 32,
          likes: 178,
          media: 221,
          color: "#F83255"
        },
        {
          title: "Exposició 'Joan Triadú a la ciutat dels llibres'",
          description: "",
          date: "7 Jul",
          startAt: "09:00",
          duration: "24 h",
          img:
            "https://multimedia-wp.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/05/26182214/Exposicio-Joan-Triadu.jpg",
          address: "Biblioteca Martorell",
          events: 18,
          participants: 496,
          comments: 32,
          likes: 178,
          media: 221,
          color: "#F8A832"
        },
        {
          title: "Inauguració exposició 'Processos'",
          description: "",
          date: "09 Jul",
          startAt: "20:00",
          duration: "1 h",
          img:
            "https://multimedia-wp.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/06/09151132/insta5-scaled.jpg",
          address: "Muxart. Espai d'Art i Creació Contemporanis",
          events: 18,
          participants: 496,
          comments: 32,
          likes: 178,
          media: 221,
          color: "#004347"
        },
        {
          title: "Concert de Mélodie Gimard",
          description: "Festival PAS",
          date: "09 Jul",
          startAt: "22:30",
          duration: "2 h",
          img:
            "https://multimedia-wp.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/06/28180807/MG_3836-46_horitzontal-scaled.jpg",
          address: "Pont del Diable",
          events: 18,
          participants: 496,
          comments: 32,
          likes: 178,
          media: 221,
          color: "#038890"
        }
      ],
      createdEvents: [],
      participatedEvents: [],
      aFollowers: [],
      isFollowed: false,
      isExternal: true,
      isEditMode: false,
      isSettingsMode: false,
      department: "",
      previewImgFile: null,
      avatarImg: "https://upload.wikimedia.org/wikipedia/commons/3/33/Mr._Bean_2011.jpg",
      privateImg:
        "https://p1.pxfuel.com/preview/272/573/172/iphone-taking-photo-phone-anonymous-royalty-free-thumbnail.jpg",
      portadaImg:
        "https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg"
    };
  }
};
</script>

<style scoped>
@media (max-width: 575px) {
  .infoRow,
  .contentRow {
    width: 100%;
    min-height: 100px !important;
  }

  .btnCol {
    padding: 0;
  }

  .hobbies {
    width: 300px;
  }

  .portada {
    width: 100%;
    height: 300px;
    margin: auto;
    background-image: url("https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg");
    background-size: cover;
  }

  .avatarImg {
    right: 60%;
  }

  .avatar {
    background-image: url("https://static.thenounproject.com/png/207964-200.png");
    background-size: cover;
    position: absolute;
    top: 30%;
    /* right: 35% !important; */
    left: 1%;
  }

  div[role="tabpanel"] {
    overflow: auto;
  }

  .portadaRow {
    margin: 0;
  }

  .type-1 {
    text-align: start;
  }
}

@media (min-width: 576px) {
  .infoRow,
  .contentRow {
    width: 80%;
  }
}

.ownEvents,
.participateEvents,
.followers,
.hobbies,
.prices {
  padding: 1rem;
  box-shadow: 5px 3px 20px 0px #17a2b8;
  border-radius: 2rem;
  margin: 1rem;
}

.portada {
  background-image: url("https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg");
  background-size: cover;
  width: 100%;
  filter: brightness(0.5);
  z-index: -1;
}

.portada > img {
  max-height: 300px;
  min-width: 80%;
}

.portadaRow {
  height: 200px;
}

.contentRow {
  display: grid;
  grid-template: ". .";
}

.infoRow,
.contentRow {
  background: white;
  min-height: 160px;
  /* width: 80%; */
  margin: auto;
}

.inputAvatar {
  display: none;
}

.avatar {
  background-image: url("https://static.thenounproject.com/png/207964-200.png");
  background-size: cover;
  position: absolute;
  top: 10%;
  /* right: 74%; */
  left: 1%;
}

.avatar > img {
  height: 200px;
}

.avatarImg,
.portadaImg {
  filter: opacity(0.5);
  cursor: pointer;
}

.col1 {
  text-align: start;
  margin-top: 4rem;
}

.intereses {
  display: flex;
}

br {
  display: contents;
}

.bestMoments {
  display: flex;
  justify-content: space-evenly;
}

.groupCardInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.groupCardInfo > div:first-child {
  text-align: start;
}

.groupCardInfo > div:last-child {
  text-align: end;
}

.groupCard {
  cursor: pointer;
  border-radius: 2rem;
  width: 100%;
  padding: 1rem;
  background: #17a2b8;
  color: white;
  box-shadow: 5px 7px 10px #00000085;
  min-width: 225px;
}

.newgroupCard {
  background:var(--main-bg);
  color: #17a2b8;
  border: 2px dotted #17a2b8;
  margin-bottom: 1rem;
}

.groupCardContainer {
  display: grid;
  grid-template: ". . .";
  grid-gap: 1rem 1rem;
}

.moreInfo {
  padding: 3rem 1rem;
  margin-left: 4rem;
  border-left: 1px solid;
}

.cardInfo {
  background: #17a2b8;
  color: white;
  border-radius: 2rem;
  box-shadow: 6px 6px 10px grey;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
}

.eventCardInfo {
  display: flex;
  justify-content: space-between;
}

.eventCardInfo > .date {
  color: #f83255;
  font-weight: 800;
  width: 20%;
}
</style>

<style>
.agendaCard > .vs-card > .vs-card__img {
  max-height: 100px;
  max-width: 100px;
}
</style>
