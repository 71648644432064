<template>
  <div class="home">
    <header>
      <nav>
        <h1 style="color:red">Invitation</h1>
        <div>Se ha verificado la invitación : {{ $route.params.id }}</div>
      </nav>
    </header>
  </div>
</template>

<script>
import { imageMix } from "@/common/mixin.js";
import axios from "axios";
import LoginBtn from "@/components/LoginBtn.vue";

export default {
  name: "Invite",
  mixins: [imageMix],
  data: function() {
    return {
      assistants: [],
      event: null
    };
  },
  methods: {
    async onValidateUser() {
      const body = {
        inviteKey: this.$route.params.id,
        userNick: localStorage.getItem("user")
      };

      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .post("/invite", body)
        .then(response => {
          if (response.data.length > 0) {
            this.event = response.data[0];
            this.getAssistants(this.event.id);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    async getAssistants(event_id) {
      const body = { params: { event_id } };
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .get("/participation", body)
        .then(response => {
          this.profileImg = this.parseImages(response.data, true);
          this.assistants = response.data;
          this.$store.commit("setEvent", {
            ...this.event,
            assistants: this.assistants,
            private: true
          });
          this.$router.push("/event");
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  mounted() {
    if (!localStorage.user) {
      this.notificateLoginRequired("top-center", "warn", LoginBtn);
    } else {
      this.onValidateUser();
    }
  }
};
</script>
