<template>
  <vs-dialog
    ref="dialogCnt"
    overflow-hidden
    v-model="active"
    class="createDialog"
    @close="onClosed"
  >
    <template #header>
      <div class="bmyCard">
        <div class="bmyCardLogo"></div>
      </div>
    </template>

    <div class="createForm">
      <div>
        <div class="bmyContent">
          <div class="bmyContentTitle">
            <!-- <img class="logoImg" loading="lazy" src="@/assets/android-chrome-512x512_green.png" /> -->

            <h2>Crear Evento</h2>
            <iCreate />
          </div>
          <div class="bmyContentInputs">
            <div class="inputs">
              <vs-switch danger v-model="isPrivate">
                <template #off> <i class="fa fa-unlock"></i> Public </template>
                <template #on> Private<i class="fa fa-lock"></i> </template>
              </vs-switch>
            </div>
            <div class="inputs" v-if="isPrivate">
              <vs-input
                type="password"
                :color="password !== '' ? 'success' : '#fff'"
                v-model="password"
                label="Contraseña:"
                label-placeholder="Pon aquí tu contraseña"
                :progress="getProgress"
                :visiblePassword="hasVisiblePassword"
                icon-after
                @click-icon="hasVisiblePassword = !hasVisiblePassword"
              >
                <template v-if="password === ''" #message-danger>
                  Required
                </template>
                <template v-if="getProgress >= 100" #message-success>
                  Secure password
                </template>
              </vs-input>
            </div>
            <div class="inputs">
              <vs-input block :value="''" label-placeholder="Nombre del plan" v-model="title">
                <!-- :label="isPrivate ? 'Nombre del plan : ' : 'Titulo del plan : '" -->
                <template v-if="title === ''" #message-danger>
                  Required
                </template>
              </vs-input>
            </div>
            <div class="inputs">
              <vs-select multiple filter placeholder="Temática" value="''" v-model="tematica">
                <vs-option-group v-for="tema in tematicas" :key="'tematica_' + tema.title">
                  <div slot="title">
                    {{ tema.title }}
                  </div>
                  <vs-option
                    v-for="(subtema, ind) in tema.subtema"
                    :key="'subtema_' + ind"
                    :label="subtema"
                    :value="subtema"
                  >
                    {{ subtema }}
                  </vs-option>
                </vs-option-group>
                <template v-if="!isPrivate" #message-danger>
                  Required
                </template>
              </vs-select>
            </div>
            <div class="inputs">
              <v-date-picker
                class="datePicker"
                v-model="dates"
                mode="dateTime"
                color="pink"
                :value="{ start: new Date(), end: new Date() }"
                is-range
                is-dark
                is24hr
                show-weeknumbers
                hint="Required"
                persistent-hint
                :available-dates="{ start: new Date(), end: null }"
                :attributes="calendarAttributes"
                :min-date="new Date()"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <vs-input
                    class="bg-white border px-2 py-1 rounded"
                    label-placeholder="Cuando es tu evento?"
                    :value="!inputValue.start ? '' : inputValue.start + '-' + inputValue.end"
                    v-on="inputEvents.start"
                  />
                </template>
              </v-date-picker>
              <small v-if="!dates.start" style="color:red">Required</small>
            </div>
            <div class="inputs">
              <places
                v-model="form.country.label"
                placeholder="Donde quedamos ?"
                @change="val => (this.form.country.data = val)"
                :options="options"
                ref="places"
              />
              <small v-if="!this.form.country.label" style="color:red">Required</small>
            </div>
            <div class="inputs">
              <vs-button
                @click="uploadImage"
                size="xl"
                gradient
                style="min-width: 60px"
                warn
                animation-type="scale"
              >
                <i class="far fa-image"></i>
                <template #animate>
                  Imagen
                </template>
              </vs-button>
              <vs-input
                ref="uploadImg"
                v-show="false"
                type="file"
                accept="image/*"
                drop-placeholder="Drop file here..."
                @change="onUploadFile($event)"
              >
              </vs-input>
            </div>
            <img loading="lazy" v-if="previewImage" :src="previewImage" class="previewImg" />
            <small v-if="!extraOptions" class="inputs last">
              <vs-button transparent mini @click.prevent.stop="extraOptions = true"
                >Opciones Avanzadas</vs-button
              >
            </small>

            <div v-if="extraOptions" class="inputs">
              <vs-switch class="inputs" warn v-model="free">
                <template #off> Gratis</template>
                <template #on> De pago <i class="fa fa-money" aria-hidden="true"></i> </template>
              </vs-switch>

              <vs-input
                v-if="free"
                type="text"
                placeholder="€"
                :color="price !== '' ? 'success' : '#fff'"
                label="Precio : "
                v-model="price"
                id="price"
              />
            </div>
            <div v-if="extraOptions" class="inputs">
              <vs-switch class="inputs" warn v-model="hasAforo">
                <template #off> Sin Aforo</template>
                <template #on> Con Aforo <i class="fa fa-people" aria-hidden="true"></i> </template>
              </vs-switch>
              <vs-input
                v-if="hasAforo"
                type="number"
                placeholder="Aforo máx. (opcional)"
                :color="aforo !== '' ? 'success' : '#fff'"
                label="Aforo : "
                v-model="aforo"
                id="aforo"
              />
            </div>
            <div v-if="extraOptions" class="inputs">
              <vs-input
                type="text"
                size="300"
                placeholder="Cuentanos un poco más..."
                :color="description !== '' ? 'success' : '#fff'"
                label="Description : "
                v-model="description"
                id="description"
              />
            </div>
            <div v-if="extraOptions" class="inputs">
              Publicar en :
              <div class="socialBtns">
                <vs-button facebook icon>
                  <i class="fab fa-facebook"></i>
                </vs-button>
                <vs-button color="slack" icon>
                  <i class="fab fa-instagram"></i>
                </vs-button>
                <vs-button color="twitter" icon>
                  <i class="fab fa-twitter"></i>
                </vs-button>
              </div>
            </div>
            <vs-button block color="#038890" border @click="onCreate">
              CREAR
            </vs-button>
            <button id="shareBtn" />
          </div>
        </div>
      </div>
    </div>
  </vs-dialog>
</template>

<script>
import Places from "vue-places";
import { iCreate } from "@/components/ILLUSTRATIONS/";
import { loadingMix, noficationsDialog, authMix, eventMix } from "@/common/mixin.js";

export default {
  name: "createEvent",
  props: ["active", "onClose"],
  mixins: [loadingMix, noficationsDialog, authMix, eventMix],
  components: {
    Places,
    iCreate
  },
  computed: {
    getProgress() {
      let progress = 0;

      if (/\d/.test(this.password)) {
        // at least one number
        progress += 20;
      }

      if (/(.*[A-Z].*)/.test(this.password)) {
        // at least one capital letter
        progress += 20;
      }

      if (/(.*[a-z].*)/.test(this.Password)) {
        // at menons a lowercase
        progress += 20;
      }

      if (this.password.length >= 6) {
        // more than 5 digits
        progress += 20;
      }

      if (/[^A-Za-z0-9]/.test(this.password)) {
        // at least one special character
        progress += 20;
      }
      return progress;
    }
  },
  methods: {
    onUploadFile(oEvent) {
      const image = oEvent.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = oEvent2 => {
        this.previewImage = oEvent2.target.result;
        this.image = image;
      };
    },

    uploadImage() {
      this.$refs.uploadImg.$el.children[0].children[0].click();
    },

    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += ` - ${format(dateTwo, this.dateFormat)}`;
      }
      return formattedDates;
    },

    onClosed() {
      const datesStr = this.formatDates(this.inputDateOne, this.inputDateTwo);
      this.onClose();
    },

    async onCreate() {
      this.openLoading();
      try {
        const {
          isPrivate,
          password,
          title,
          tematica,
          image,
          price,
          aforo,
          description,
          dates,
          form,
          startTime,
          endTime
        } = this;
        const apiURL = "https://graph.facebook.com/v11.0/";
        const pageId = "707648483408872";
        const accessToken =
          "?access_token=EAAMdzmdFjKYBAHUiClnfYVImzj9WxnmB9q9ZALYbgwKAxNxRrZAkZAF1Kytg4ewPqqK1qqfjf6UciYAzxatz6TsQsIwvZBZCdTZBwJZAnZBW34xWT0ZAVxvm9Laz1vGJpsi2P5SLdqvFhR2tAZBqk5czkb3xiUSUGtixeD7SUfZA2bMg1DHsSZCfTZCgz9307s7RhZAfDD7SZAY1lHSSyu9nECRFCTd";
        fetch(apiURL.concat(pageId + "/feed" + accessToken), {
          method: "POST",
          body: "message=" + title
        })
          .then(response => response.json())
          .then(data => console.log(data));
        const currentUser = await this.firebaseCurrentUser();
        const startDate = startTime
            ? dates.start.toDateString().concat(" " + startTime)
            : dates.start.toDateString(),
          endDate = endTime
            ? dates.end.toDateString().concat(" " + endTime)
            : dates.end.toDateString(),
          location = form.country.data,
          city = location.name,
          created_by = currentUser.uid,
          modified_by = created_by;
        const body = {
          title,
          description,
          isPrivate,
          price,
          city,
          location,
          created_by,
          modified_by,
          aforo,
          startDate,
          endDate,
          password,
          tematica
        };
        const event = await this.createEvent(body);
        if (this.image && event.id) {
          this.setEventImg(event.id, this.image);
        }
        this.notificateLoginSuccess(
          "Has creado un evento con éxito",
          "A continuación serás dirigido a la lista de eventos"
        );
        let that = this;
        if (navigator.share) {
          await navigator
            .share({
              title: "B YOUR PLAN ",
              text: "Nuevo evento creado",
              url: window.location.href.concat("/event/" + event.id)
            })
            .then(() => {
              setTimeout(function() {
                that.$router.push({
                  name: "Event",
                  params: { id: event.id }
                });
              }, 3000);
            });
        }
        this.closeLoading();
      } catch (error) {
        console.log(error);
        this.closeLoading();
      }
    }
  },
  data() {
    return {
      extraOptions: false,
      title: "",
      tematica: [],
      description: "",
      isPrivate: false,
      price: 0,
      hasAforo: false,
      aforo: 0,
      image: null,
      dateFormat: "D MMM",
      dateOne: new Date(),
      dateTwo: "",
      dates: [],
      previewImage: null,
      free: false,
      password: "",
      hasVisiblePassword: false,
      range: {
        start: new Date(),
        end: new Date().setDate(new Date().getDate() + 7)
      },
      calendarAttributes: [
        {
          key: "today",
          highlight: {
            color: "purple",
            fillMode: "outline"
          },
          dates: new Date()
        },
        {
          dot: "green",
          dates: [
            new Date(),
            new Date().setDate(new Date().getDate() + 3),
            new Date().setDate(new Date().getDate() + 6)
          ],
          popover: {
            label: "Festa Major Martorell"
          },
          customData: {
            isComplete: false,
            dates: { weekdays: 6 },
            color: "red"
          }
        }
      ],
      options: {
        appId: null,
        apiKey: null,
        // postcodeSearch: true,
        countries: ["es"],
        useDeviceLocation: true
      },
      form: {
        country: {
          label: null,
          data: {}
        }
      },
      tematicas: [
        {
          title: "ACTIVIDADES DE OCIO",
          subtema: [
            "Ocio",
            "Aniversarios",
            "Excursiones",
            "Fiestas populares",
            "Fiestas para toda la familia",
            "Fiestas para niños",
            "Fiestas para adultos",
            "Parques temáticos y de agua",
            "Quedadas",
            "Streamings"
          ]
        },
        {
          title: "CULTURA",
          subtema: [
            "Cultura",
            "Tours y rutas",
            "Visitas guiadas",
            "Rutas de arte e historia",
            "Conferencias",
            "Videoconferencias o webinar"
          ]
        },
        {
          title: "DEPORTES Y AVENTURAS",
          subtema: [
            "Deportes y aventuras",
            "Acuáticos",
            "Aéreos",
            "Atletismo",
            "Ciclismo",
            "Colectivos",
            "Combinados o múltiples",
            "Con animales",
            "Con raqueta",
            "Cursos deportivos",
            "De agarre/fuerza",
            "De aventura/extremos",
            "De baile",
            "De combate, lucha y relajación",
            "De deslizamiento",
            "De invierno",
            "De mesa/mentales",
            "De montaña",
            "De motor",
            "De precisión",
            "De pelota",
            "De realidad virtual",
            "Ferias de deporte",
            "Otros deportes"
          ]
        },
        {
          title: "GASTRONOMÍA",
          subtema: [
            "Gastronomía",
            "Cursos de cata y coctelería",
            "Catas de vinos y otros",
            "Degustaciones y experiencias gastronómicas",
            "Ferias de comidas",
            "Visitas a bodegas y viñedos"
          ]
        },
        {
          title: "CURSOS",
          subtema: [
            "Cursos",
            "Cursos online",
            "De cocina",
            "De fotografia",
            "De idiomas",
            "De manualidades",
            "De crecimiento personal",
            "De música",
            "De deporte",
            "De gastronomía",
            "De baile",
            "De belleza"
          ]
        },
        {
          title: "BELLEZA",
          subtema: [
            "Adelgazamiento",
            "Cursos de belleza",
            "Manicuras y pedicuras",
            "Peluqiería",
            "Presoterapia",
            "Sesiones de fotos",
            "Tratamientos"
          ]
        },
        {
          title: "BIENESTAR",
          subtema: ["Bienestar", "Masajes", "Spas", "Salud"]
        }
      ]
    };
  }
};
</script>

<style>
.vc-popover-content-wrapper.is-interactive {
  z-index: 1000;
}

.datePicker > div {
  padding: 0 !important;
  border: none !important;
}

.vs-dialog__header {
  padding: 0;
}

.ap-input {
  border: 2px solid transparent;
  background: rgba(var(--vs-gray-2), 1);
  color: rgba(var(--vs-text), 1);
  padding: 7px 13px;
  border-radius: inherit;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  padding-left: 10px;
  width: 200px;
}

.ap-dataset-places {
  background: #212529;
  text-align: start;
}

.ap-suggestion:hover {
  background: #a10673;
}

.ap-suggestion .ap-cursor {
  background: #060f1d;
}
</style>

<style scoped>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@600&display=swap");

.whiteLogo {
  top: 85px;
  width: 100%;
  position: relative;
  left: 0;
}

.socialBtns {
  display: flex;
  width: 100%;
}

.inputs {
  justify-content: center;
  display: grid;
  margin-bottom: 1.5rem;
}

.inputs:last-child {
  margin-bottom: 0;
}

/* // Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */
@media (max-width: 575px) {
  .bmyCard {
    width: 100%;
    max-width: none !important;
  }
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .bmyCard {
    width: 100%;
    max-width: none !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .header {
    margin: 5rem;
    margin-bottom: 3rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.previewImg {
  height: 50px;
  width: 50px;
}

.bmyCard {
  font-family: "Cairo", sans-serif;
  max-width: 500px;
  margin: auto;
  background: linear-gradient(0, #900358, #f83255);
  color: #038890;
  height: 20%;
  max-height: 75px;
  min-height: 50px;
  border-radius: 1rem 0 0;
}

.bmyCardLogo {
  background-image: url("../assets/white_transparent.png");
  background-size: 100%;
  background-position-y: 15px;
  margin: auto;
  width: 45%;
  height: 100px;
}

.bmyContent {
  color: #038890;
  max-width: 500px;
  margin: auto;
  background: white;
  text-align: center;
  padding: 1rem 1rem 0 1rem;
  /* margin-bottom: 1rem; */
}

.bmyContentTitle {
  display: flex;
}

.bmyContentInputs {
  margin-top: 1rem;
}

.logoImg {
  height: 50px;
  width: 50px;
}
</style>
