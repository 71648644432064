<template>
  <vs-card :type="user.type === 1 ? '3' : '3'" v-if="user" id="userCard">
    <template #title v-if="user.type === 1">
      <h3>
        {{ user.isPrivate && isExternal ? "Nombre" : user.firstname }}
        {{ user.isPrivate && isExternal ? "Privado" : user.lastname }}
      </h3>
    </template>
    <template #title v-else>
      <h2>
        <b> @{{ user.username }} </b>
      </h2>
    </template>
    <template #img>
      <img
        v-if="avatarImg"
        :src="`${user.isPrivate && isExternal ? privateImg : avatarImg}`"
        alt="Image 1"
      />
      <iMan v-else-if="!avatarImg" />
      <iWoman v-else-if="avatarImg === 2" />
    </template>
    <template #text v-if="user.type === 1">
      <p>{{ user.nombre }} {{ user.apellidos }}</p>
      <i>{{
        user.isPrivate && isExternal ? "Calle falsa 123" : "Martorell, Barcelona y Alrededores"
      }}</i>
      <p>Aficionado a : <b>Deporte</b> y <b>Fiesta</b></p>
      <div
        style="display: flex;
    justify-content: space-between;"
      >
        <p>
          Followers : <b>{{ user.followers }}</b>
        </p>
        <p>
          Following : <b>{{ user.followers }}</b>
        </p>
      </div>
    </template>
    <template #text v-else>
      <div>
        <p>
          <i class="fas fa-users"></i> Followers : <b>{{ user.followers || 0 }}</b>
        </p>
        <p>
          <i class="fas fa-calendar-alt"></i> Eventos : <b>{{ user.followers || 0 }}</b>
        </p>
        <em><a href="https://ajuntament.martorell.cat">Pagina Web</a> </em>
      </div>
    </template>
    <template #interactions v-if="user.type === 1">
      <vs-button danger icon v-if="isExternal">
        <i class="fas fa-heart"></i>
      </vs-button>
      <vs-button
        class="btn-chat"
        v-if="isEditMode"
        shadow
        primary
        @click="onChangeImg($event, 'avatar')"
      >
        <i class="fas fa-pencil-alt"></i>
      </vs-button>
    </template>
    <template #interactions v-else>
      <div class="userInteractions">
        <div class="actionBtns">
          <vs-button danger icon v-if="!isExternal && !isEditMode">
            <i class="fas fa-heart"></i>
          </vs-button>
          <vs-button
            class="btn-chat"
            v-if="isEditMode"
            shadow
            primary
            @click="onChangeImg($event, 'avatar')"
          >
            <i class="fas fa-pencil-alt"></i>
          </vs-button>
        </div>
        <div class="socialBtns">
          <vs-button facebook icon v-if="!isExternal">
            <i class="fab fa-facebook"></i>
          </vs-button>
          <vs-button color="slack" icon v-if="!isExternal">
            <i class="fab fa-instagram"></i>
          </vs-button>
          <vs-button color="twitter" icon v-if="!isExternal">
            <i class="fab fa-twitter"></i>
          </vs-button>
        </div>
      </div>
    </template>
  </vs-card>
</template>

<script>
import { iMan, iWoman } from "@/components/ILLUSTRATIONS/";

export default {
  name: "UserCard",
  components: {
    iMan,
    iWoman
  },
  props: ["type", "user", "isExternal", "isEditMode", "avatarImg"],
  methods: {
    onChangeImg(event, type) {
      this.$emit("onChangeImg", { type, event });
    }
  },
  data: () => {
    return {};
  }
};
</script>

<style scoped>
.userInteractions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.socialBtns {
  display: flex;
  width: 100%;
}

.actionBtns {
  display: flex;
  width: 100%;
}
</style>

<style>
#userCard > div > div > div {
  width: 100%;
}
</style>
