<template>
  <div>
    <div class="blockHeader">
      <h4>
        Followers
      </h4>
      <vs-button circle icon floating @click.stop="getProfiles">
        <i class="fas fa-plus-circle"></i>
      </vs-button>
    </div>
    <div class="followersCnt">
      <vs-card
        @click="goProfile(follower.follower_Id)"
        type="2"
        v-for="(follower, ind) in followers"
        :key="'follower_' + ind"
      >
        <!-- <template #title>
        <h3>Apellido</h3>
      </template> -->
        <template #img>
          <img :src="img2" alt="" />
        </template>
        <template #text>
          <p>
            {{ follower.follower_Id }}
          </p>
        </template>
        <!-- <template #interactions>
          <vs-button danger icon>
            <i class="bx bx-heart"></i>
          </vs-button>
          <vs-button class="btn-chat" shadow primary>
            <i class="bx bx-chat"></i>
            <span class="span">
              54
            </span>
          </vs-button>
        </template> -->
      </vs-card>
    </div>
    <vs-dialog width="300px" not-center v-model="active3">
      <template #header>
        <h4 class="not-margin">Busqueda de perfiles</h4>
      </template>

      <div class="con-content">
        <vs-input
          large
          @input="filterUser"
          v-model="filterName"
          placeholder="Buscar perfil"
        ></vs-input>
      </div>
      <div class="userListItem" v-for="user in filteredFollowers" :key="'newuser_' + user.nick">
        <vs-avatar color="rgb(205,55,95)">
          <img v-if="user.ProfileImg" :src="user.ProfileImg" alt="" />
          <template v-if="!user.ProfileImg" #text>
            {{ user.nick }}
          </template>
        </vs-avatar>
        <div class="userLIText">
          <h6>{{ user.nick }}</h6>
        </div>
        <div class="userAB">
          <vs-button
            v-if="isFollowed(user.nick)"
            color="#ff3e4e"
            circle
            icon
            floating
            @click.stop="follow(user.nick)"
          >
            <i class="fas fa-heart fa-xs"></i>
            <template #animate>
              <i class="fas fa-heart"></i>
            </template>
          </vs-button>
          <vs-button icon floating circle color="#5B8200" animation-type="vertical">
            <i class="fas fa-comment-dots"></i>
            <template #animate> <i class="fas fa-comment-dots"></i> </template>
          </vs-button>
        </div>
      </div>

      <template #footer>
        <div class="con-footer">
          <vs-button @click="active3 = false" danger block>
            Close
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { imageMix } from "@/common/mixin.js";

export default {
  name: "FollowersList",
  mixins: [imageMix],
  props: ["followers"],
  data() {
    return {
      active3: false,
      filterName: "",
      users: [],
      filteredFollowers: [],
      img2:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQuJCEfooUBWITHKYc7CrcrIs61S6OP_BFiFkWmCwWrx3aiV9v1&usqp=CAU"
    };
  },
  methods: {
    onRedirectPage() {
      this.$emit("onRedirectPage", this.$el.children[0].id);
    },

    isFollowed(nick) {
      return !this.$props.followers.some(follower => {
        return follower.follower_Id === nick;
      });
    },

    filterUser() {
      let array = this.users;
      if (this.filterName !== "") {
        array = array.filter(user => {
          return user.nick.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase());
        });
      }
      this.filteredFollowers = array;
    },

    goProfile(username) {
      this.$emit("changeProfile", username);
    },

    async getProfiles() {
      this.active3 = true;
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .get("/profile", {})
        .then(response => {
          const currentUser = this.$store.state.currentUser.nick;
          this.users = this.parseImages(response.data, _, true);
          let myInd = this.users.findIndex(a => {
            return a.nick === currentUser;
          });
          if (myInd > -1) {
            this.users.splice(myInd, 1);
          }
          this.filteredFollowers = this.users;
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style scoped>
.followersCnt {
  display: grid;
  grid-template-columns: auto auto auto;
}
.blockHeader,
.btnRow,
.con-footer {
  display: flex;
  justify-content: space-between;
}

.userListItem,
.userAB {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.con-content {
  margin-bottom: 1rem;
}
</style>
